import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CiMail, CiLocationOn } from "react-icons/ci";
import { FiPhone } from "react-icons/fi";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function Contact() {

  const defaultProps = {
    center: {
      lat: -6.170910193792884,
      lng: 106.80249216692269
    },
    zoom: 18
  };

  return (
    <div className="margin-template">
    <Row>
        <Col md={12} lg={6}>
          <div className="subtitle">CONTACT US</div>
            <div className="title-light">
              COLLABORATE WITH US AND ACHIEVE 
              <span className="purple"> YOUR GOALS </span> WITH
              <span className="purple"> OUR EXPERT </span>
              BUILDING SOLUTIONS
            </div>

          <div className="subtitle">Email</div>
          <div className="contact-flex">
            <CiMail className="contact-icon" /><a className="link-color" href="mailto:deltakaryakonstruksi@gmail.com" target="_blank">deltakaryakonstruksi@gmail.com</a> 
          </div>
          <div className="subtitle">Phone Number</div>
          <div className="contact-flex">
            <FiPhone className="contact-icon" /><a className="link-color" href="https://api.whatsapp.com/send?phone=6281230767206" target="_blank">+62 8123 0767 206</a> 
          </div>
          <div className="subtitle">Address</div>
          <div className="contact-flex">
            <CiLocationOn className="contact-icon" /><a className="link-color" href="https://www.google.com/maps/place/PT+Delta+Karya+Konstruksi/@-6.1711022,106.799928,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69f719576c2793:0x7c2e2715ed71347f!8m2!3d-6.1711022!4d106.8025029!16s%2Fg%2F11tmwyhl70?entry=ttu" target="_blank">PT Delta Karya Konstruksi<br></br>Rawa Kepa V No.732B, Tomang<br></br>Jakarta Barat</a> 
          </div>

        </Col>
         
        <Col md={12} lg={6}>
          <div style={{ height: '40vh', width: '100%' }}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.6985531578052!2d106.79992261119114!3d-6.171102193790462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f719576c2793%3A0x7c2e2715ed71347f!2sPT%20Delta%20Karya%20Konstruksi!5e0!3m2!1sen!2sid!4v1720108683996!5m2!1sen!2sid" 
         className="maps" 
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
            {/* <GoogleMapReact
              bootstrapURLKeys={{ key: "" }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            >
              <AnyReactComponent
                lat={-6.170910193792884}
                lng={106.80249216692269}
                text="Delta Karya Konstruksi"
              />
            </GoogleMapReact> */}
          </div>
        </Col>

    </Row>
    </div>
  )
}

export default Contact