import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navigation from './Components/Navigation';
import Footer from './Components/Footer';
import ScrollToTop from './Components/ScrollToTop';
import Home from "./Pages/Home";
import About from "./Pages/About";
import Projects from './Pages/Projects';
import Contact from './Pages/Contact';


function App() {

  
  return (
    <>
      <Router>
      <ScrollToTop>
      <Navigation />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
        <Footer />
        </ScrollToTop>
      </Router>
      
    </>
  );
}

export default App;
