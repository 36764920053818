import React from 'react';
import Button from 'react-bootstrap/Button';
import { FaChevronRight } from "react-icons/fa";
import '../App.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

function About() {

  const content= [
    {
      content: "PT Delta Karya Konstruksi began its journey in the construction industry by focusing on the industrial sector, including building warehouses, factories, docks, buildings, storage tanks, and more. Armed with the extensive background of its founders, who have long been involved in industrial construction, we started from small-scale projects, accumulating valuable experience along the way."
    },
    {
      content: "Today, we have grown into a competent and trusted company, capable of handling larger and more complex projects. With a team of skilled and trained professionals, PT Delta Karya Konstruksi prioritizes quality, efficiency, and delivering satisfying results for our clients. The trust we have earned from various stakeholders has resulted in numerous construction achievements across the industrial and building sectors."
    },
    {
      content: "As a testament to our continuous learning process and accumulated experience, PT Delta Karya Konstruksi employs effective and efficient construction management and methods. We are ready to provide the best construction services, creating tangible and impressive construction works."
    }
  ]
  const vision= [
    {
      content: "PT Delta Karya Konstruksi aspires to be one of the premier construction companies in Indonesia, prioritizing sustainable development by enhancing workforce expertise, optimizing technology management, and regularly updating technology and information systems."
    }
  ]
  const mission= [
    {
      content: "PT Delta Karya Konstruksi began its journey in the construction industry by focusing on the industrial sector, including building warehouses, factories, docks, buildings, storage tanks, and more. Armed with the extensive background of its founders, who have long been involved in industrial construction, we started from small-scale projects, accumulating valuable experience along the way."
    }
  ]

  return (
    <>
    <div className="hero-banner-about">
    <div className="padding-template">
        {/* <div className="title" >
          FULFILL YOUR AMBITIONS 
        </div> */}
        {/* <Link to="/contact">
        <Button className="button">
          Contact us <FaChevronRight />
        </Button></Link> */}
        </div>
    </div>

    <div className="margin-template">
      <div className="subtitle">About us</div>
        <Row>
          <Col md={12} lg={6}>
            <div className="title-light">
              WE ARE A COLLECTIVE OF
              <span className="purple"> ARCHITECTS, DESIGNER, ENGINEERS </span>
              AND PLANNERS WORKING TOGETHER TO BUILD
              <span className="purple"> A BETTER FUTURE</span>
            </div>
          </Col>
          <Col md={12} lg={6}>
          {content.map(function(data){
            return(
              <>
                {data.content} <br></br><br></br>
              </>
            )
          })}
          </Col>
        </Row>
        <hr></hr>
    </div>
    <div className="margin-template">
      <div className="subtitle">Vision & <span className="purple">Mision</span></div>
        <Row>
          <Col md={12} lg={6}>
            <div className="title-light">
              Vision
              {/* <span className="purple"> </span> */}
            </div>
          </Col>
          <Col md={12} lg={6}>
            {vision.map(function(data){
            return(
              <>
                {data.content} <br></br><br></br>
              </>
            )
          })}
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={6}>
          <div className="title-light">
              Mission
            </div>
        </Col>
          <Col md={12} lg={6}>
        {mission.map(function(data){
            return(
              <>
                {data.content} <br></br><br></br>
              </>
            )
          })}
          </Col>
        </Row>
        <hr></hr>
    </div>

    <div className="margin-template">
        <div className="d-flex align-items-center ">
          <hr className='line' />
          <div className='subtitle'>Est. 2017</div>
        </div>
        PT Delta Karya Konstruksi stands as a leading construction firm dedicated to delivering innovative and high-quality solutions for infrastructure development in Indonesia. Established in 2017, we have committed ourselves to contributing to sustainable and environmentally friendly development. With a spirit of innovation and unwavering dedication, PT Delta Karya Konstruksi is ready to be your premier partner in every construction project. Together, let's build a better future!
    </div>

    <div className="about-banner">
      <div className="margin-template">
      <Row>
        <Col md={12} lg={6} className="banner-title">
        Interested in collaborating with us?
        Reach out today with just a click of a button!
        </Col>
            <Col md={12} lg={6} className="center">
        <Link to="/contact">
            <Button className="buttonwhite">
                Contact Us <FaChevronRight />
            </Button>

        </Link>
            
        </Col>
      </Row>
          
      </div>
    </div>

    <div className="margin-template">
    <div className="display-flex">
          <div className="about-subtitle">Snippets of our Projects</div>
          <Link to="/projects"><Button className="button-long">Go to Projects</Button> </Link>
               
    </div>
    </div>
    
    <Row className="pb-5">
      <Col md={6} lg={3}>
        <div className="area1"></div>
      </Col>
        <Col md={6} lg={3}>
          <div className="area2"></div>
        </Col>
        <Col md={6} lg={3}>
          <div className="area3"></div>
        </Col>
        <Col md={6} lg={3}>
          <div className="area4"></div>
        </Col>
    </Row>

    </>
  )
}

export default About