import {
    Berau, Berau2, Berau3, Berau4, Berau5, Berau6, Berau7, Berau8, Berau9, Berau10, Berau11, Berau12, Berau13, Berau14, Berau15, Berau16, Berau17,
    Kalimantan1, Kalimantan2, Kalimantan3, Kalimantan4, Kalimantan5, Kalimantan6, Kalimantan7, Kalimantan8,
    CPO1, CPO2, CPO3, CPO4,
    Engine1, Engine2, Engine3, Engine4, Engine5, Engine7, Engine8,
    Fuel1, Fuel2, Fuel3, Fuel4,
    Coast1, Coast2, Coast3, Coast4, Coast5, Coast6, Coast7,
    Sby1, Sby2, Sby3, Sby4, Sby5, Sby6, Sby7, Sby8, Sby9, Sby10, Sby11, Sby12, Sby13,
    Sememi1, Sememi2, Sememi3, Sememi4, Sememi5, Sememi6, Sememi7, Sememi8,
    Lemonilo1, Lemonilo2, Lemonilo3, Lemonilo4, Lemonilo5,
    Cut1, Cut2, Cut3, Cut4, Cut5,
    Design1, Design2, Design3, Design4, Design5, Design6, Design7,
    Build1, Build2, Build3, Build4, Build5, Build6, Build7,
    Cipi1, Cipi2, Cipi3, Cipi4, Cipi5, Cipi6, Cipi7, Cipi8, Cipi9, Cipi10,
    Smg1, Smg2, Smg3, Smg4, Smg5,
    Office1, Office2, Office3, Office4, Office5, Office6, 
    Bali1, Bali2, Bali3, Bali4, Bali5, Bali6, Bali7, Bali8, Bali9, Bali10, Bali11,
} from '../assets/index';


export const projects = [
    {
        name: "CPO Jetty Construction",
        location: "Berau, Kalimantan",
        tags: "Construction",
        gallery: [Berau, Berau2, Berau3, Berau4, Berau5, Berau6, Berau7, Berau8, Berau9, Berau10, Berau11, Berau12, Berau13, Berau14, Berau15, Berau16, Berau17]
    },
    /*{
        name: "Pump and Boiler Building",
        location: "Berau, Kalimantan",
        tags: "Construction",
        gallery: [Kalimantan1, Kalimantan2, Kalimantan3, Kalimantan4, Kalimantan5, Kalimantan6, Kalimantan7, Kalimantan8]
    },*/
    /*{
        name: "CPO Storage Tank",
        location: "Berau, Kalimantan",
        tags: "Construction",
        gallery: [CPO1, CPO2, CPO3, CPO4]
    },
    {
        name: "Engine Room Construction",
        location: "Berau, Kalimantan",
        tags: "Construction",
        gallery: [Engine1, Engine2, Engine3, Engine4, Engine5, Engine7, Engine8]
    },
    {
        name: "Fuel Tank & Water Treatment Plant",
        location: "Berau, Kalimantan",
        tags: "Construction",
        gallery: [Fuel1, Fuel2, Fuel3, Fuel4]
    },*/
    {
        name: "Coastway Jetty",
        location: "Bone Manjing - Sulawesi Barat",
        tags: "Construction",
        gallery: [Coast1, Coast2, Coast3, Coast4, Coast5, Coast6, Coast7]
    },
    {
        name: "Plastic Warehouse",
        location: "Surabaya",
        tags: "Construction",
        gallery: [Sby1, Sby2, Sby3, Sby4, Sby5, Sby6, Sby7, Sby8, Sby9, Sby10, Sby11, Sby12, Sby13]
    },
    {
        name: "Warehouse",
        location: "Sememi - Surabaya",
        tags: "Construction",
        gallery: [Sememi1, Sememi2, Sememi3, Sememi4, Sememi5, Sememi6, Sememi7, Sememi8]
    },
    {
        name: "PT. Lemonilo Indonesia Sehat",
        location: "Jakarta",
        tags: "Construction",
        gallery: [Lemonilo1, Lemonilo2, Lemonilo3, Lemonilo4, Lemonilo5]
    },
    {
        name: "Cut & Fill",
        location: "Cilandak - Jakarta",
        tags: "Construction",
        gallery: [Cut1, Cut2, Cut3, Cut4, Cut5]
    },
    {
        name: "Villa NW",
        location: "Ubud - Bali",
        tags: "Architecture",
        gallery: [Bali1, Bali2, Bali3, Bali4, Bali5, Bali6, Bali7, Bali8, Bali9, Bali10, Bali11]
    },
    {
        name: "Design Concept Cold Storage Factory",
        location: "Bangka",
        tags: "Architecture",
        gallery: [Design1, Design2, Design3, Design4, Design5, Design6, Design7]
    },
    {
        name: "Office Building",
        location: "Surabaya",
        tags: "Construction",
        gallery: [Build1, Build2, Build3, Build4, Build5, Build6, Build7]
    },
    {
        name: "Boarding House",
        location: "Cipinang - Jakarta",
        tags: "Construction",
        gallery: [Cipi1, Cipi2, Cipi3, Cipi4, Cipi5, Cipi6, Cipi7, Cipi8, Cipi9, Cipi10]
    },
    {
        name: "Office",
        location: "Semarang",
        tags: "Construction",
        gallery: [Smg1, Smg2, Smg3, Smg4, Smg5]
    },
    {
        name: "Office - Interior",
        location: "Semarang",
        tags: "Construction",
        gallery: [Office1, Office2, Office3, Office4, Office5, Office6]
    },

]