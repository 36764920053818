import React from 'react';
import '../App.css';
import { useState} from 'react';
import { Link } from 'react-router-dom';


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FaChevronRight } from "react-icons/fa";
import {projects} from '../Components/Data';
import "yet-another-react-lightbox/styles.css";




function Projects() {
  const [datas, setData] = useState(projects);

  const displayData = datas.map((data, name)=>
    <>
      <div className="projects-card">
        <div className="projects-title">
          <div className="bold">{data.name}</div>
          <div>{data.tags}</div>
        </div>
        {data.location}
        <div id="grid">
        {data.gallery?.map((name, increment) => (
          <>
            <a target="_blank" rel="noreferrer" href={data.gallery[increment]}>
            <img className="image-gallery" src={data.gallery[increment]} alt="" />
            </a>
          </>
        ))}
        </div>

      </div>
  </>
  )

  const filterResult = (catItem) => {
    const result = projects.filter((curData) => {
      return curData.tags === catItem;
    });
    setData(result);

  }




  return (
    <>
      <div className="hero-banner">
        <div className="padding-template">
          {/* <div className="title">
            FULFILL YOUR
            CONSTRUCTION SOLUTIONS
          </div> */}
          {/* <Link to="/contact">
            <Button className="button">
              Contact us <FaChevronRight />
            </Button></Link> */}
        </div>
      </div>

      <div className="margin-template">
        <div className="subtitle">PROJECTS</div>
        <div className="title-light">
          SHIFT THROUGH OUR COLLECTIONS OF 
          <span className="purple"> PROJECTS </span>
           AND 
          <span className="purple"> COLLABORATIONS </span>
            WE DID 
          <span className="purple"> OVER THE YEARS </span>
        </div>
          <hr></hr>
      </div>

      <div className="margin-template">
      <Row>
        <Col md={4} lg={3} className=" filter" >
          FILTER BY: 
            <Button className="filter-button " onClick={() => setData(projects)}>SHOW ALL</Button>
            <Button className="filter-button" onClick={() => filterResult('Construction')}>CONSTRUCTION</Button>
            <Button className="filter-button" onClick={() => filterResult('Architecture')}>ARCHITECTURE</Button>
            {/* <Button className="filter-button" onClick={() => filterResult('Engineering')}>ENGINEERING</Button> */}
        </Col>
          <Col>
          
          {displayData}

          </Col>
      </Row>

      </div>

    </>
  )
}

export default Projects