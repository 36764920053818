import React from 'react';
import './Footer.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

import { CiMail, CiLocationOn } from "react-icons/ci";
import { FiPhone } from "react-icons/fi";
import Logo from "../assets/logo-white.png";




function Footer() {

    const quicklink = [
        {
            name: 'Home', 
            path: "/",
        },
        {
            name: 'About',
            path: "/about",
        },
        {
            name: 'Projects',
            path: "/projects",
        },
        {
            name: 'Contact Us',
            path: "/contact",
        },

    ];

    const contactus = [
        {
            name: "deltakaryakonstruksi@gmail.com",
            icon: <CiMail />,
        }, 
        {
            name: "+62 812 307 672 06",
            icon: <FiPhone />,
        },
        {
            name: "Rawa Kepa V No.732B, Tomang - Jakarta Barat",
            icon: <CiLocationOn />,
        },
    ]



  return (
    <div className="footer-body">
        <Row>
            <Col md={12} lg={3}>
                  <img src={Logo} alt="" className="footer-logo" />
                <div className="subtitle">
                SHAPE VISION INTO REALITY
                </div>
            </Col>
              <Col md={12} lg={2}></Col>
              <Col md={12} lg={2}>
                  <div className="subtitle footer-padding">QUICK LINK</div>
                  {quicklink.map(function (data) {
                      return (
                          <div className="footer-link">
                              <Link className="footer-link" to={data.path}>{data.name}</Link><br></br>
                          </div>
                      )
                  })}
              </Col>
              <Col md={12} lg={5}>
                  <div className="subtitle footer-padding">CONTACT US</div>
                  <div className="contact-flex">
                      <CiMail className="footer-icon" /> <a className="link-color-f" href="mailto:deltakaryakonstruksi@gmail.com" target="_blank">deltakaryakonstruksi@gmail.com</a>
                  </div>
                  <div className="contact-flex">
                      <FiPhone className="footer-icon" /> <a className="link-color-f" href="https://api.whatsapp.com/send?phone=6281230767206" target="_blank">+62 8123 0767 206</a>
                  </div>
                  <div className="contact-flex">
                      <CiLocationOn className="footer-icon" /> <a className="link-color-f" href="https://www.google.com/maps/place/PT+Delta+Karya+Konstruksi/@-6.1711022,106.799928,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69f719576c2793:0x7c2e2715ed71347f!8m2!3d-6.1711022!4d106.8025029!16s%2Fg%2F11tmwyhl70?entry=ttu" target="_blank">PT Delta Karya Konstruksi<br></br>Rawa Kepa V No.732B, Tomang<br></br>Jakarta Barat</a>
                  </div>

              </Col>
        </Row>
    </div>
  )
}

export default Footer