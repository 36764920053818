import React from 'react';
import { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import './Navigation.css';
import Logo  from "../assets/logo-white.png";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { MdOutlineMenu } from "react-icons/md";



function Navigation() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
      <Navbar className="navbar-body">
            <Link to={"/"} className="navlink"> 
                Home
            </Link>
            <Link to={"/about"} className="navlink">
                About
            </Link>
            <img src={Logo} alt="" className="logo" />
            <Link to={"/projects"} className="navlink">
                Projects
            </Link>
            <Link to={"/contact"} className="navlink">
                Contact Us
            </Link>
                  <Button className="btn-responsive" onClick={handleShow}>
                  <MdOutlineMenu />
                  </Button>
        </Navbar>

            

          <Offcanvas show={show} onHide={handleClose}>
              <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Menu</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                  <img src={Logo} alt="" className="logo" />
                  <Link onClick={handleClose} to={"/"} className='responsive-link' >
                      Home
                  </Link>
                  <Link onClick={handleClose} to={"/about"} className='responsive-link' >
                      About
                  </Link>
                  <Link onClick={handleClose} to={"/projects"} className='responsive-link'>
                      Projects
                  </Link>
                  <Link onClick={handleClose} to={"/contact"} className='responsive-link'>
                      Contacts Us
                  </Link>
              </Offcanvas.Body>
          </Offcanvas>
      </> 
  )
}

export default Navigation