import React from 'react';
import '../App.css';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaChevronRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Logo from '../assets/logo.png';

import Image1 from '../assets/coast1.png';



function Home() {

  const areas = [{
    name: 'ENGINEERING', 
    description: "Our team of skilled engineers acts as the brains behind every project, from the initial sketch to the final touches. We combine cutting-edge technology with proven engineering principles to create exceptional construction plans. Our team boasts specialists in various fields, including structural, mechanical, and civil engineering. This allows us to tackle any project, big or small. We meticulously design your project, ensuring it’s safe, functional, and stunning. Our engineers stay on site, guaranteeing every step adheres to plans and regulations. We anticipate and solve challenges before they slow you down.",
  },
  {
    name: 'CONSTRUCTIONS', 
    description: "Imagine the possibilities. We transform your vision into reality, from the first sketch to the final ribbon-cutting.  PT Delta Karya Konstruksi is your one-stop construction partner, bringing expertise and a collaborative spirit to every project. Building a Brighter Future, One Project at a Time. We specialize in a wide range of construction projects. We are committed not only to exceptional construction but also to environmental responsibility. We incorporate sustainable practices throughout every project, minimizing our footprint and building a greener future for generations to come.",
  },
  {
    name: 'ARCHITECTURE',
    description: "Designing Your Dream Space! We turn ideas into stunning and functional buildings. Our passionate architects are storytellers, weaving beauty, practicality, and green features into every design. We have a team with a wide range of styles, from classic to modern. We create detailed plans and models to bring your dream space to life. We use eco-friendly materials and techniques for a greener future. We work closely with engineers and contractors to ensure your project runs smoothly.",
  },
  ];





  return (
    <>
    <div>
        <Row className="home-hero-title">
          <Col md={12} lg={6} className="home-hero-subtitle">
            <img src={Logo} className="home-logo" alt="" />
          <div className="d-flex align-items-center ">
            <hr className='line'/>
            <div className='subtitle'>Est. 2017</div>
          </div>
          {/* <div className='title'> */}
          <div className='title-light'>
            {/* WE CHANGE */}
            SHAPE
            <span className="purple"> VISION </span>
            INTO<span className="purple"> REALITY</span>
            {/* FULFILL YOUR AMBITIONS & */}
            {/* CONSTRUCTION SOLUTIONS */}
          </div>
          </Col>

          <Col md={12} lg={6} className="home-hero-subtitle">
            <b>PT Delta Karya Konstruksi</b><br></br>
            Your trusted construction partner. We have tons of experience and are committed to build your dreams with precision and quality. We handle all your construction needs, both residential or industrial, big or small. Contact us today to discuss your vision.
            <div className="pt-3">
            <Link to="/contact">
              <Button className="button">
                Contact us <FaChevronRight />
              </Button></Link>
            </div>
          </Col>
        </Row>
    </div>
      <img src={Image1} alt="" className='hero-image' />


    <div className="margin-template">
      <div className="title-light">
        WE ARE A COLLECTIVE OF 
        <span className="purple"> ARCHITECTS, DESIGNER AND ENGINEERS </span>
        WORKING TOGETHER TO BUILD 
        <span className="purple"> A BETTER FUTURE</span> 
      </div>

      <div class="container">
        <div className="Area-1"></div>
        <div className="Area-2"></div>
          <div className="Area-3"></div>
          <div className="Area-4"></div>
          <div className="Area-5"></div>
      </div>
        
    </div>

    <div className="margin-template">
      <Row>
        <Col md={12} lg={6}>
            <div className="subtitle">Areas of Expertise</div>
            {areas.map(function (data) {
              return (
                <>
                <div className="subtitle pt-4">{data.name}</div>
                <hr></hr>
                <div >{data.description}</div>
                </>
              )
            })}
        </Col>
          <Col md={12} lg={6}>
        <div className="area-image"></div>
        </Col>
      </Row>

    </div>

    <div className="margin-template center ">
          <Link to="/projects">
            <Button className="button-long">
              Check out our Projects <FaChevronRight />
            </Button>
          </Link>
    </div>





    </>
  )
}

export default Home